const searchToggle = ((w, d) => {
  'use strict';

  const $searchIcon = d.querySelector('[data-js="searchIcon"]');
  const $body = d.querySelector('body');

  const listener = (element, event, callback) => {
    element.addEventListener(event, callback, 'false');
  };

  listener($searchIcon, 'click', (e) => {
    e.preventDefault;
    $body.classList.toggle('active-search');
  });
})(window, document);
