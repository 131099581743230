let owl = ((w, d) => {
  'use strict';

  $('.banners').owlCarousel({
    autoplay: true,
    loop: true,
    margin: 0,
    items: 1,
    navText: ["<img src='/img/icons/arrow-left.png'>", "<img src='/img/icons/arrow-right.png'>"],
    nav: true,
    autoplayTimeout: 8000
  });

  $('.carousel-barba').owlCarousel({
    autoplay: false,
    loop: false,
    autoHeight:true,
    margin: 0,
    items: 1,
    URLhashListener:true,
    startPosition: 'URLHash',
    navText: ["<img src='/img/icons/arrow-left.png'>", "<img src='/img/icons/arrow-right.png'>"],
    nav: true
  });

  $('.carousel-sub').owlCarousel({
    autoplay: false,
    loop: false,
    margin: 0,
    items: 1,
    navText: ["<img src='/img/icons/arrow-left.png'>", "<img src='/img/icons/arrow-right.png'>"],
    dots: true,
    URLhashListener: true,
    startPosition: 'URLHash',
    nav: true
  });
})(window, document);
