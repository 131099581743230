const responsiveMenu = ((w,d) => {
  'use strict';

  const $menuIcon = d.querySelector('[data-js="menuIcon"]');
  const $menu = d.querySelector('[data-js="menu"]');

  const listener = (element, event, callback) => {
    element.addEventListener(event, callback, 'false');
  };

  listener($menuIcon, 'click', (e) => {
    e.preventDefault();
    $menu.classList.toggle('active');
  });
})(window, document);
