const productIdeal = ((w, d) => {
    'use strict';

    var $public = {};

    var productIdeal = (function() {

        $public.question = function() {
            var quests = $(".productideal").find(".productideal-quest");
            quests.first().addClass("activeQuest").fadeIn(5);
            
            $(".productideal input").on( "change", function() {

                var nextQuestion =  $(this).data('next-question');

                if (nextQuestion) {

                    var showDiv = $("div[data-question='"+nextQuestion+"']");
                    $(".activeQuest").removeClass("activeQuest").fadeOut(500, function() {
                        $(showDiv).fadeIn(300).addClass("activeQuest");
                    });

                } else {
                    $public.submit(quests.parent());
                }

          });
        };

        $public.questionMobile = function() {
            var quests = $(".productideal").find(".productideal-select-align");
            quests.first().addClass("activeQuestMobile").fadeIn(5);

            $(".productideal .selectredirect").on( "change", function() {
                window.location.href = $(this).val();
            });
            
            $(".productideal .selectsubmit").on( "change", function() {


                var nextQuestion =  $('option:selected', this).data('next-question');

                if (nextQuestion) {
                    var showDiv = $("div[data-question-mobile='"+nextQuestion+"']");

                    $(".activeQuestMobile").removeClass("activeQuestMobile").addClass("productideal-disabled");
                    $(showDiv).fadeIn(300).addClass("activeQuestMobile");
                    /*$(".productideal-select").select2({
                        minimumResultsForSearch: Infinity
                    });*/

                } else {
                    $public.submit(quests.parent());
                }

                /*var showDiv = $(".activeQuestMobile").next();
                
                if(showDiv.length > 0) {
                    $(".activeQuestMobile").removeClass("activeQuestMobile").addClass("productideal-disabled");
                    $(showDiv).fadeIn(300).addClass("activeQuestMobile");
                    $(".productideal-select").select2({
                        minimumResultsForSearch: Infinity
                    });
                } else {
                    $public.submit(quests.parent());
                }*/
          });
        };

        $public.select2 = function() {
            $(".productideal-select").select2({
                minimumResultsForSearch: Infinity
            });
        };

        $public.submit = function(formSubmit) {
            formSubmit.submit();
        };

        $public.sendForm = function() {
          $(".sendForm").ajaxForm({
            dataType: 'text',
            resetForm: true,
            beforeSubmit: function(data, $form) {
                $form.find('.productideal-validate').html("");
                $form.find('.productideal-alert').fadeOut(1);
                $form.find('.productideal-submiting').fadeIn(500);
            },
            success: function (responseJSON, statusText, data, xhr) {

                xhr.find('.productideal-submiting').fadeOut(200);
                $(".productideal-article").fadeOut(500);
                $(".productideal-products").fadeIn(500);
                var addHtml = data.responseText;
                $(".productideal-products .productideal-products-cards").html(addHtml);

                
            },
            error: function (data, responseText, statusText, xhr) {
                xhr.find('.productideal-submiting').fadeOut(200);

                if (data.status == 422) {
                    var fields = JSON.parse(data.responseText);
                    xhr.find('.productideal-alert').html("erro no envio").fadeIn(300).delay(2000).fadeOut(200);
                } else {

                    var response = JSON.parse(data.responseText);
                    xhr.find('.productideal-alert').html(response.feedback.error).fadeIn(300).delay(2000).fadeOut(200);
                }
            }
          });

          $(".sendFormMobile").ajaxForm({
              dataType: 'text',
              resetForm: true,
              beforeSubmit: function(data, $form) {
                  $form.find('.productideal-validate').html("");
                  $form.find('.productideal-alert').fadeOut(1);
                  $form.find('.productideal-submiting').fadeIn(500);
              },
              success: function (responseJSON, statusText, data, xhr) {

                  xhr.find('.productideal-submiting').fadeOut(200);
                  $(".productideal-products").fadeIn(500);
                  var addHtml = data.responseText;
                  $(".productideal-products .productideal-products-cards").html(addHtml);
                  $('.productideal-article').fadeOut(1);

                  
              },
              error: function (data, responseText, statusText, xhr) {
                  xhr.find('.productideal-submiting').fadeOut(200);

                  if (data.status == 422) {
                      var fields = JSON.parse(data.responseText);
                      xhr.find('.productideal-alert').html("erro no envio").fadeIn(300).delay(2000).fadeOut(200);
                  } else {

                      var response = JSON.parse(data.responseText);
                      xhr.find('.productideal-alert').html(response.feedback.error).fadeIn(300).delay(2000).fadeOut(200);
                  }
              }
          });
        };

        return $public;
    })();

// Global
window.productIdeal = productIdeal;
productIdeal.question();
productIdeal.questionMobile();
productIdeal.sendForm();
productIdeal.select2();
})(window, document);
