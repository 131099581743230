const products = ((w, d) => {
    'use strict';

    var $public = {};

    var product = (function() {

        $public.filter = function() {
            $(".filter__button").on( "click", function() {
                var showDiv = $(this).attr("data-show");
                $("."+showDiv).stop().toggle(300);
          });
        };

        $public.carousel = function() {
            $('.related-product__carousel').owlCarousel({
                loop:false,
                dots: false,
                margin:0,
                nav:true,
                responsive:{
                    0:{
                        navText: ['<img src="/img/icons/arrow-left-mobile.png">','<img src="/img/icons/arrow-right-mobile.png">'],
                        items:2
                    },
                    1000:{
                        navText: ['<img src="/img/icons/arrow-left.png">','<img src="/img/icons/arrow-right.png">'],
                        items:4
                    }
                }
            })
        }

        return $public;
    })();

// Global
window.product = product;
product.filter();
product.carousel();
})(window, document);
