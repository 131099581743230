const lightbox = ((w, d) => {
    'use strict';

    var $public = {};

    var lightbox = (function() {

        $public.modal = function() {
            $(".modal-buy").on( "click", function() {
                $(".lightbox").fadeOut(0);
                var lightboxList = $(".lightbox").find(".lightbox-list");
                lightboxList.html('');

                $(".lightbox").fadeIn(200);
                var urlGetPartials = $(this).attr("href");
                var product = $(this).attr("product");

                // product consult

                var total = 0;
                for (var i = 0; i < 8; i++) {
                    var urlConsult = urlGetPartials + '?product=' + product + '&type=' + i;
                    $.ajax({
                      url : urlConsult,
                      type : 'GET',
                      data : '',
                      success: function(data){
                        lightboxList.append(data);
                        total++
                        if (total == 8) {
                            var totalFound = lightboxList.find("li").length;
                            console.log(totalFound);
                            if(totalFound === 0) {
                                lightboxList.append("<p>Desculpe, mas atualmente não temos o produto disponível a venda nas nossas lojas patrocinadas.</p>");    
                            }
                            
                        }
                      }
                    });
                }
                

                return false;
            });

            $(".lightbox-close").on( "click", function() {
                $(".lightbox").fadeOut(200);
                var lightboxList = $(".lightbox").find(".lightbox-list");
                lightboxList.html('');
            });

        };

        return $public;
    })();

// Global
window.lightbox = lightbox;
lightbox.modal();
})(window, document);
